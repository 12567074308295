.affiliate-program-page {
  overflow: auto;
  width: 100%;
  height: var(--settings__body-height);
  position: relative;
  z-index: 0;
  background: var(--settings__body-bg-color);
  margin: var(--settings__body-margin);
  padding: var(--settings__body-padding);
}

.affiliate-program-page__main-header {
  max-width: calc(var(--retail-setting__body-max-width) / 1.5);
  background: var(--settings__body-header-bg-color);
  margin: var(--settings__body-header-margin);
  position: relative;
}

.affiliate-program-page__main-header:before {
  display: var(--settings__accent-bar-display);
  content: '';
  background-image: var(--accent-bar__gradient);
  min-width: 100vw;
  height: calc(
    100% + (var(--settings__body-header-margin) * 2) +
      (var(--settings__body-padding) * 4)
  );
  position: absolute;
  z-index: -1;
  top: calc(((var(--settings__body-padding) * 2) + 2px) * -1);
  bottom: 0;
  left: calc(
    (
        var(--side-menu__item-width) + var(--settings__body-header-margin) +
          var(--settings__body-padding) + 2px
      ) * -1
  );
  right: 0;
}

.affiliate-program-page__header {
  font-size: var(--settings__body-header-title-font-size);
  color: var(--settings__body-header-title-font-color);
  letter-spacing: var(--settings__body-header-title-letter-spacing);
  padding-bottom: var(--settings__body-header-title-padding-bottom);
}

.affiliate-program-page__description {
  max-width: calc(var(--retail-setting__body-max-width) / 1.5);
  /* font-size: var(--settings__body-header-sub-title-font-size); */
  color: var(--settings__body-header-sub-title-font-color);
  letter-spacing: var(--settings__body-header-sub-title-letter-spacing);
  /* line-height: var(--settings__body-header-sub-title-line-height); */
}

.affiliate-program-page__body {
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
}

.affiliate-program-page__container {
  width: calc(50% - 3rem);
  max-width: 50rem;
  min-width: 30rem;
  border-radius: var(--affiliate-program-page__border-radius);
  border: solid 1px var(--affiliate-program-page__border-color);
  background: var(--component__bg-color);
  color: var(--component__color-primary);
  margin: var(--settings__body-header-margin);
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: var(--settings__card-box-shadow);
}

.affiliate-program-page__container-header {
  border-bottom: solid 1px var(--affiliate-program-page__border-color);
  display: flex;
  align-items: center;
  border-top-left-radius: var(--affiliate-program-page__border-radius);
  border-top-right-radius: var(--affiliate-program-page__border-radius);
  padding: var(--settings__card-header-padding);
  color: var(--settings__card-header-title-font-color);
  background: var(--settings__card-header-bg-color);
  font-size: var(--settings__card-header-title-font-size);
  border-bottom: solid 1px var(--component__header-separator-color);
  letter-spacing: var(--settings__card-header-title-letter-spacing);
}
.affiliate-program-page__container-header h1 {
  font-size: var(--settings__card-header-title-font-size);
}

.affiliate-program-page__container-header--title {
  margin: 0;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .affiliate-program-page {
    height: unset;
    flex: 1;
  }

  .affiliate-program-page__main-header:before {
    left: calc(
      (var(--settings__body-header-margin) + var(--settings__body-padding)) * -1
    );
  }
}
