.problem-logging-in-page__container {
  width: 60%;
}

.problem-logging-in-page__header {
  height: 6.8rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 2px solid var(--standalone-form__header-border-color);
}

.problem-logging-in-page__header-text {
  font-size: 4rem !important;
}

.problem-logging-in-page__footer {
  margin: 0;
  padding: 0 2.8rem;
  background: transparent !important;
}

.problem-logging-in-page__link {
  font-size: 1.2rem;
  color: white !important;
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 750px) {
  .problem-logging-in-page__container {
    width: auto;
  }
}
