.welcomeCard {
    display: flex;
    background-color: rgba(256, 256, 256, 0.4);
    border-radius: 5px;
    border-color: #454545;
    margin: 0 auto;
    margin-top: 5%;
    min-height: 500px;
    max-width: 600px;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 50px;
}

.cardItems {
    min-height: 200px;
}

.cardElements {
    margin-bottom: 20px;
}

.welcomeList {
    margin-bottom: 40px;
}

.welcomeListItem {
    font-size: 1.2rem;
    text-align: left;
    margin: 10px 0;
}

.welcomeBtn {
    min-width: 200px;
    box-shadow: 0px 8px 15px rgb(0 0 0 / 40%);
}