:root {
  --standalone-form__container-width: 46rem !important;
  --standalone-form__header-border-color: rgb(66, 131, 252) !important;
  --success__bg-color: rgb(66, 131, 252) !important;
  --success__color: rgb(66, 162, 252) !important;
  /*--exchange-colors__buy: rgb(21,159,73) !important;
    --exchange-colors__sell: rgb(240,84,53) !important;*/
  --link__color: rgb(110, 175, 255) !important;

  --component__border-color--glow: rgb(66, 131, 252) !important;
  --accent-bar__gradient: linear-gradient(260deg, #cc01ff 0%, #00ffdd 100%) 0%
    0% no-repeat padding-box !important;
  --tab__border-color--selected: rgb(66, 131, 252) !important;

  /* Exchange Page */
  --padded-decimal__units-buy-color: rgb(26, 201, 90) !important;
  --padded-decimal__decimals-buy-color: rgb(26, 201, 90) !important;
  --padded-decimal__padding-buy-color: rgb(26, 201, 90) !important;
  --padded-decimal__separator-buy-color: rgb(26, 201, 90) !important;

  --padded-decimal__units-color: black !important;
  --padded-decimal__decimals-color: black !important;

  --settings__trade-reports-create-new-actions-btn-bg-color: rgb(
    66,
    131,
    252
  ) !important;
  --settings__api-keys-create-new-actions-btn-bg-color: rgb(
    66,
    131,
    252
  ) !important;
  --settings__api-keys-create-new-actions-documentation-btn-font-color: rgb(
    110,
    175,
    255
  ) !important;
  --side-menu__item-accent-color--selected: rgb(66, 131, 252) !important;
}

/* HEADER LOGO and Signup page logo */
.ap-logo__img {
  width: 300px !important;
  height: 75px !important;
}
.page-header__header-item--logo .ap-logo .ap-logo__img {
  max-width: 20rem !important;
}
.page-header__header-item--logo {
  width: 30rem !important;
}
/* ------------------------------------- Verify Email PAGE CSS ------------------------------------- */
.standalone-modal__wrapper {
  background: #121212 !important;
}
.standalone-modal__container {
  margin-top: 10rem !important;
  background: none !important;
  box-shadow: none !important;
  border: 1px solid rgb(59, 59, 59) !important;
  border-radius: 5px !important;
}
.standalone-modal__body {
  background: none !important;
}
.standalone-modal__logo {
  margin: 0px !important;
  margin-top: 4rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0 !important;
  position: relative !important;
}
.standalone-modal__body-text {
  font-size: 1.6rem !important;
}

/* ------------------------------------- GLOBAL LOGIN PAGE CSS ------------------------------------- */
.standalone-layout__wrapper {
  background: #fff !important;
}
.standalone-layout__logo {
  margin: 0px !important;
  margin-top: 4rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0 !important;
  position: relative !important;
}
.standalone-form__container {
  margin-top: 4rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.standalone-form__header {
  border-bottom: none !important;
  background: #fff !important; /* fallback for old browsers */
  padding: 0 !important;
  align-items: center !important;
}

.standalone-form__header-text {
  margin-left: auto !important;
  margin-right: auto !important;
  font-size: 2.5rem !important;
  text-align: center !important;
  color: #4283fc !important;
}
.ap-icon--close {
  display: none !important;
}

.standalone-form__form {
  margin: 0 !important;
  padding: 3rem 2.8rem 2.8rem !important;
  border: 1px solid rgb(59, 59, 59) !important;
  border-radius: 5px;
}
.standalone-form__footer {
  background-color: #121212 !important;
}
/* ------------------------------------- LOGIN PAGE CSS ------------------------------------- */
.login-form__input {
  height: 5.6rem !important;
  border-radius: 5px;
  background: #fff !important;
  border: 1px solid rgb(59, 59, 59) !important;
  font-size: 1.6rem !important;
}
.login-form__input:hover {
  border: 1px solid #4283fc !important;
}
.login-form__input:focus {
  border: 3px solid #4283fc !important;
}
.login-form__separator {
  margin: 0rem 0em 1rem 0rem !important;
  border: none !important;
}
.standalone-form__footer {
  background-color: #fff !important;
  text-align: center !important;
  border: 0px solid rgb(59, 59, 59) !important;
}
.standalone-form__success {
  padding: 3rem !important;
  font-size: 2.5rem !important;
  line-height: 1.6 !important;
  border: none !important;
  border-radius: 5px;
}
.login-form__btn {
  height: 4rem !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #fff !important;
}
.ap--label {
  font-size: 1.6rem !important;
  line-height: 1.2 !important;
}
.login-form__link {
  font-size: 1.7rem !important;
  color: white !important;
  font-weight: 1000;
}
.login-form__footer {
  padding: none !important;
  margin: auto !important;
}
/* ------------------------------------- SIGNUP PAGE CSS ------------------------------------- */
.signup-page__container {
  border-radius: 0 !important;
  margin-bottom: 2rem !important;
}
.signup-page__header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.signup-form__container {
  padding: 0 !important;
  border-bottom: none !important;
}
.signup-form__input-wrapper {
  margin-top: 0 !important;
  margin-bottom: 2.8rem !important;
}
.signup-form__input {
  height: 5.6rem !important;
  border-radius: 5px;
  background: #fff !important;
  border: 1px solid rgb(59, 59, 59) !important;
  font-size: 1.6rem !important;
  color: #000 !important;
}
.signup-form__input:hover {
  border: 1px solid #4283fc !important;
}
.signup-form__input:focus {
  border: 3px solid #4283fc !important;
}
.signup-form__footer {
  padding: 1rem 0rem 0rem 0rem !important;
}
.signup-page__footer {
  margin: auto !important;
}
.signup-form__btn {
  height: 4rem !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  color: #fff !important;
}
.signup-form__label {
  line-height: 1.2 !important;
  letter-spacing: -0.5px !important;
}
.signup-page__link {
  font-size: 1.7rem !important;
  color: #4283fc !important;
}

/* --------------------------- Problem Logging In Page -------------------------------*/
.problem-logging-in-page__container {
  border-radius: 0 !important;
  margin-bottom: 2rem !important;
}
.problem-logging-in-page__header {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: transparent !important;
}
.problem-logging-in-page__header-text {
  color: white !important;
}
.forgot-password-form__information-text {
  font-size: 2rem !important;
}
.forgot-password-form__form {
  border: none !important;
}
.forgot-password-form__label {
  font-size: 1.8rem !important;
}
.forgot-password-form__input {
  height: 5.6rem !important;
  border-radius: 5px;
  background: #fff !important;
  border: none !important;
  font-size: 1.6rem !important;
  color: #000 !important;
}
.forgot-password-form__input:hover {
  border: 1px solid #4283fc !important;
}
.forgot-password-form__input:focus {
  border: 3px solid #4283fc !important;
}
.forgot-password-form__separator {
  margin: 0rem 0em 1rem 0rem !important;
  border: none !important;
}
.forgot-password-form__btn--additive {
  color: yellow !important;
  width: 50% !important;
  background: #380047 !important;
}
.problem-logging-in-page__link {
  font-size: 1.7rem !important;
  color: #4283fc !important;
}
/* --------------------------------- NAV BAR --------------------------------------------*/

.user-summary__username-in-display {
  font-size: 1.5rem !important;
  color: #4283fc !important;
}

.user-summary__popover-menu-trigger:hover {
  background-color: var(--nav-item__bg-color--selected) !important;
}
.user-summary__popover-menu-trigger {
  color: #4283fc !important;
}

.user-summary__popover-menu-trigger:focus {
  background-color: var(--nav-item__bg-color--selected) !important;
}

.ap-popover__content {
  background: rgb(41, 43, 48) !important;
}

.ap-popover__content:hover {
  background: var(--nav-item__bg-color--selected) !important;
}

.popover-menu__item {
  color: #4283fc !important;
}
.popover-menu__item:hover,
.popover-menu__item:active,
.popover-menu__item:focus {
  background: var(--nav-item__bg-color--selected) !important;
}

/* -------------------------- PAGE FOOTER --------------------------------------*/
.page-footer__footer-left-content {
  font-size: 1.5rem !important;
  width: 20% !important;
}
.page-footer__footer-right-content {
  width: 80% !important;
}
.page-footer {
  min-height: 23rem !important;
  padding: 4.4rem 12.5rem 0 12.5rem !important;
}
.page-footer a {
  display: block !important;
  font-size: 1.5rem !important;
  line-height: 1.8 !important;
  color: #0defdf !important;
}
.page-footer__footer-left-content {
  /* color: #898989 !important; */
}
.page-footer__icon {
  padding-top: 10rem !important;
}
/* ----------------------------------- Dashboard ----------------------------------------*/
.balance-item__product {
  font-size: 1.5rem !important;
  /* color: black !important; */
}
.balance-item__position {
  /* color: black !important; */
}

/* .activity__action-text {
    color: black !important;
}
.activity__amount-quantity {
    color: black !important;
}
.activity__status-id {
    color: black !important;
}
.activity__date-date {
    color: black !important;
} */
/* ---------------------------------------Buy Sell---------------------------------------- */
.buy-sell-make-transaction__title {
  font-size: 2.5rem !important;
  letter-spacing: normal !important;
  /* color: #b81afc !important; */
}
.buy-sell__tab {
  font-size: 2rem !important;
}
.eotc-buy-sell-layout__balances-container {
  padding: 5rem 0 5rem 2rem !important;
}
.buy-sell-balances__lwt-container {
  padding: 1rem 2.7rem 0 0 !important;
}
.buy-sell-balances__title {
  font-size: 1.75rem !important;
  padding-bottom: 1.9rem !important;
}
.buy-sell-order-overview {
  padding: 5rem 0 5rem 2rem !important;
}
.buy-sell-order-overview__title {
  font-size: 1.75rem !important;
  padding-bottom: 1.9rem !important;
}
.buy-sell-order-overview__lwt-container {
  height: 4rem !important;
}

/* ---------------------------------------Exhcange---------------------------------------- */

/* --------- Others -------- */
.ap-tab__menu {
  border-radius: 5px 5px 0 0 !important;
}
/*WALLET + USD DEPOSITS SIDE TAB*/

.fiat-sidepane__main {
  overflow: scroll;
}

.fiat-sidepane__main-form {
  height: fit-content !important;
}

.slide-pane__content {
  background: #fafafa;
}

.send-receive-tabs__tab:first-child {
  border-right: 1px solid rgb(0, 0, 0, 0.12) !important;
}

.send-receive-tabs__tab {
  border-bottom: 1px solid rgb(0, 0, 0, 0.12) !important;
}

.send-receive__side {
  width: 100% !important;
}

.send-receive__main {
  min-width: 54.5rem !important;
}

.fiat-tabs {
  color: black !important;
}

/* FAIT SIDE PLANE */
.manual-deposit__title {
  font-size: 2rem !important;
}
.manual-deposit__bank-info-label {
  padding: 5rem 3rem 1rem !important;
  color: white !important;
  font-size: 2rem !important;
}
.manual-deposit__bank-info {
  border: 0px !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  padding: 1.5rem 2.3rem 3rem 2.3rem !important;
  margin: 1rem 2rem 1rem !important;
}
.manual-deposit__step-item {
  font-size: 1.4rem !important;
  font-weight: 200 !important;
  line-height: 1.2 !important;
}
.manual-deposit__step {
  font-size: 1.4rem !important;
  margin-top: 1rem !important;
}

.manual-deposit__footer {
  border-top: 0px !important;
}
.retail-sidepane-with-details__container {
  max-width: 100rem !important;
}
.fiat-sidepane__side {
  width: 50rem !important;
}
.fiat-sidepane__main {
  width: 100rem !important;
}
.ap-label-with-text__text {
  font-size: 1.5rem !important;
}
.ap-password-input__icon {
  z-index: 65535;
}
.ap-label-with-text.manual-deposit__lwt-container {
  padding-top: 20px !important;
}
.manual-deposit__lwt-label {
  font-size: 1.2rem !important;
  min-width: 15rem !important;
}
.Gov_ID_span {
  font-size: 1.5rem;
}
.manual-deposit__select-wrapper,
.manual-deposit__input-wrapper {
  padding-bottom: 0.1rem !important;
}

.sidepane-details__header {
  font-size: 2.6rem !important;
}
.sidepane-details__information-text {
  font-size: 1.4rem !important;
}
.send-address__input {
  color: #0defdf !important;
}
.send-form__input {
  color: #0defdf !important;
}
.receive-form__input {
  color: #0defdf !important;
}
.manual-deposit__input {
  color: #0defdf !important;
}
.fiat-withdraw-form__input {
  color: #0defdf !important;
}
/* FIAT Withdraw Tab */
.fiat-withdraw-form__input-wrapper,
.fiat-withdraw-form__select-wrapper {
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 968px) {
  .instrument-row .instrument-table .instrument-table__row {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
}

@media only screen and (max-width: 720px) {
  .page-header-nav__item:not(.page-header-nav__item--selected) {
    background: rgb(41, 43, 48) !important;
  }
  .page-footer {
    min-height: 30rem !important;
    padding: 4.4rem 4.4rem 2rem 4.4rem !important;
  }
  .page-footer__icon {
    margin-top: 5% !important;
    margin-left: 0% !important;
    padding-top: 10rem !important;
  }
}
@media only screen and (max-width: 480px) {
  .standalone-form__container {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .standalone-layout__logo {
    margin-top: 4rem !important;
  }
  .standalone-form__form {
    border: none !important;
    padding: 1.5rem 0rem 2.8rem !important;
  }
  .page-footer__footer-left-content {
    width: 100% !important;
    margin-bottom: 5rem !important;
  }
  .fiat-sidepane__main {
    width: 100% !important;
    padding: 0 !important;
  }
  .fiat-sidepane__side {
    width: 100% !important;
  }
}

/* Tradeview page  */
.trading-layout__body {
  border-top: none !important;
}

/* Funded Accounts */

.wallet-card__symbol-label {
  /* color: black !important; */
}
.wallet-card__amount-text {
  /* color: black !important; */
}
.wallet-card__activity-link {
  color: #0defdf !important;
}

/* footer */

.page-footer {
  color: #0defdf !important;
}
.page-footer__footer-right-content {
  color: #0defdf !important;
}

/* coin detail  */

.product-details-page__menu {
  /* background: rgba(256, 256, 256, 0.5) !important; */
}
.balances-menu__item-value-symbol {
  /* color: black !important; */
}

/* Transfer request */
.transfer-request-notification-list {
  background: black !important;
}
/* for 2FA */
.ap-auto-tab-input {
  background: white !important;
  caret-color: black !important;
  color: black !important;
}
.ap-padded-decimal {
  color: white !important;
  font-family: Arial !important;
}
.ap-padded-decimal--buy,
.ap-padded-decimal--sell,
.ap-padded-decimal {
  font-family: Arial !important;
  font-size: 1.2rem !important;
}

.ap-padded-decimal__units {
  color: white !important;
}
.ap-padded-decimal__decimals {
  color: white !important;
}
.order-entry__rt-label {
  color: white !important;
}

.order-entry__rt-label:hover {
  background-color: transparent !important;
}
.order-entry__input {
  color: #0defdf !important;
  text-align: right !important;
}
/* website template V2  */
.order-entry__label-in-input {
  color: yellow !important;
}
.order-entry__select {
  color: white !important;
}
.advanced-order-form__select {
  color: white !important;
}
.advanced-order-form__input {
  color: #0defdf !important;
  text-align: right !important;
}
.form-control:focus {
  color: #ffffff !important;
}
.form-control {
  color: #ffffff !important;
}
.confirm-order-modal__sold {
  border-radius: 30px !important;
}
.confirm-order-modal__bought {
  border-radius: 30px !important;
}
.snackbar {
  justify-content: center !important;
}
.snackbar__text {
  font-size: 2.5rem !important;
}
.order-entry__label-in-input {
  font-size: 1.7rem !important;
}
.confirm-order-modal__bought__header {
  justify-content: center !important;
  font-size: 3rem !important;
}
.confirm-order-modal__sold__header {
  justify-content: center !important;
  font-size: 3rem !important;
}
.advanced-order-form__label-in-input {
  font-size: 1.5rem !important;
  color: yellow !important;
}
.advanced-order-form__error {
  font-size: 1.5rem !important;
}
.order-entry__label-in-input {
  position: relative !important;
}
.advanced-order-form__label-in-input {
  position: relative !important;
}
.kyc-settings__header-text {
  font-size: 3rem !important;
}
.kyc-settings__description {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}
.user-settings-page__title {
  font-size: 3rem !important;
}
.user-settings-page__sub-title {
  font-size: 1.5rem !important;
}
.profile-and-security-widget__header-text {
  line-height: 6rem !important;
}
.trade-report-page__activity-report-header-title {
  font-size: 3rem !important;
}
.trade-report-page__activity-report-header-text {
  font-size: 2rem !important;
  line-height: 3rem !important;
}
.api-keys-page__header-title {
  font-size: 3rem !important;
}
.api-keys-page__header-text {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}
.user-contacts-page__header-text {
  font-size: 3rem !important;
}
.user-contacts-page__text {
  font-size: 1.5rem !important;
  line-height: 2.5rem !important;
}
.affiliate-program-page__header {
  font-size: 3rem !important;
}
.affiliate-program-page__description {
  font-size: 1.7rem !important;
  line-height: 2.5rem !important;
}
.asset-activity-details-header__product-icon {
  margin-right: 0rem !important;
}
.margin-instrument-selector-row__details {
  color: #0defdf !important;
}
.instrument-selector-popover__content {
  width: 70vh !important;
}
.instrument-selector-popup__body {
  width: auto !important;
}
.instrument-selector-popup__column {
  text-align: left !important;
  margin-left: 40px;
  font-family: Arial !important;
  font-size: 1.3rem !important;
}
.sidepane-details__information-text.sidepane-details__information-text--deposit {
  font-size: 2rem !important;
}
.ap-button__btn--additive {
  background: var(--success__bg-color);
}
.buy-sell__select-label {
  font-size: 2.5rem !important;
}
.buy-sell__tab--sell-selected {
  border-bottom: 4px solid var(--exchange-colors__sell) !important;
  border-right: 4px solid var(--exchange-colors__sell) !important;
  border-bottom-right-radius: 10px !important;
}
.buy-sell__tab--buy-selected {
  border-bottom: 4px solid var(--exchange-colors__buy) !important;
  border-left: 4px solid var(--exchange-colors__buy) !important;
  border-bottom-left-radius: 10px !important;
}
.custom-snackbar--info {
  background-color: #4283fc !important;
}
.label-in-input {
  position: relative !important;
  color: yellow !important;
}
.instrument-table__title {
  font-size: 1.7rem !important;
}
.instrument-table__row--last-price {
  border: none !important;
}
.instrument-row {
  margin-bottom: 1rem !important;
}
.instrument-selector__symbol {
  font-size: 2.1rem !important;
}
.order-entry__rt-label--additive {
  font-size: 1.5rem !important;
}
.order-entry__rt-label--checked-additive {
  font-size: 1.9rem !important;
  border-bottom: 4px solid var(--exchange-colors__buy) !important;
}
.order-entry__rt-label--subtractive {
  font-size: 1.5rem !important;
}
.order-entry__rt-label--checked-subtractive {
  font-size: 1.9rem !important;
  border-bottom: 4px solid var(--exchange-colors__sell) !important;
}
.ap-button__btn {
  font-size: 1.4rem !important;
}
.reset-password-form__btn.reset-password-form__btn--general {
  background-color: #4283fc !important;
  font-size: 2rem !important;
}
.asset-activity-details-header__clear__btn--general {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
}
.activity-reporting-buttons__btn--general {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
  width: 100% !important;
}
.api-keys-side-pane-button__btn--general {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
}
.api-keys-page__documentation {
  background-color: #4283fc !important;
  font-size: 1.7rem !important;
}
.cant__login a {
  color: #0defdf !important;
}
.cant_login_sto a {
  font-size: 1.7rem !important;
}
.login_username {
  font-size: 3rem !important;
}
.buy-options-container .label-in-input {
  position: absolute !important;
}
.kyc-level__change__btn {
  background: var(--warning__bg-color) !important;
}
.ap-modal__header {
  font-size: 2rem !important;
}
.enable-2fa-modal__text-item {
  font-size: 1.5rem !important;
}
.instrument-selector-popup__24h-positive {
  color: var(--padded-decimal__decimals-buy-color) !important;
}
.HCSdetails {
  font-size: 13px !important;
  line-height: 15px !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.loginSto_news {
  font-size: 3.5rem !important;
  color: greenyellow !important;
  /* padding: 100px; */
}
.price-history {
  border-bottom: solid 1px white !important;
}
.volume-history {
  border-bottom: solid 1px white !important;
}
