.offering-profile__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.offering-profile__header {
  padding-left: 34px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offering-profile__symbol {
  background-color: #4283FC;
  border-radius: 4px;
  padding: .5rem 1rem;
  font-size: 20px;
  color: #fff;
}

.offering-profile__product {
  font-size: 24px;
}

.offering-profile__left {
  font-size: 16px;
}

.offering-profile__right {
  display: flex;
  font-size: 16px;
}

.offering-profile__buy {
  background: #4BCB7C;
}
.offering-profile__sell {
  background: #13B5FF;
}
.offering-profile__padding {
  padding: 2rem;
}

.offering-profile__tabs {
  border-bottom: 2px solid  #4283FC !important;
  padding: 5px;
}

.offering-profile__symbol {
  background-color: #4283FC !important;
  color: #fff !important;
}

.offering-profile__container {
  height: 100vh;
}
.offering-profile__iframe-container {
  display: flex;
  background: rgb(250, 251, 253);
  height: 100%;
  width: 100%;
}

.digital-assets__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .digital-assets__footer {
    margin-top: 3.4rem;
  }
  .offering-profile__symbol {
    display: none !important;
  }
  .offering-profile__tabs {
      padding: 0rem !important;
  }
  .offering-profile__left {
      font-size: 16px;
      margin-left: -20px !important;
  }
  .offering-profile__header {
      margin-bottom: 10px !important;
  }
}
