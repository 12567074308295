.digital-assets__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.digital-assets__container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.digital-assets__col--lg {
  width: 100%;
}

.digital-assets__container-header {
  padding: 3rem 3rem 3.6rem;
  font-size: 5rem !important;
}
.digital-assets__container-STOnews {
  /* padding: 1.5rem 1.5rem 1rem; */
  font-size: 5rem !important;
  width: 100%;
  text-align: center;
}

.digital-assets__container-notfound {
  padding: 3rem 3rem 3.6rem;
  font-size: 1.4rem;
  color: var(--warning__color);
}

.digital-assets__container-with-shadow {
  overflow: hidden;
  border-radius: 1rem;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.digital-assets__card {
  width: 362px;
  margin-bottom: 2em;
  background-color: rgba(256, 256, 256, 0.2) !important;
  border-radius: 1rem;
  position:relative;
  /* box-shadow: 1px 1px 1px 1px #fff ; */
}

.digital-assets__card-symbol {
  background-color: var(--exchange-colors__buy);
  margin-left: 28px;
  border-radius: 5px;
  padding: 1rem 2rem;
  display: inline-block;
  position: absolute;
  top: -6px;
  font-size: 20px;
}

.digital-assets__header-div {
  color: white;
  font-size: 20px;
  height: 250px;
  background-size: cover;
  background-position: 50% 50%;
  text-align: center;
  border-radius: 1rem 1rem 0 0;
}
.digital-assets__header-image {
  width: 359px;
}

.digital-assets__card-title {
  /* color: var(--font__color-primary);; */
  font-weight: 1000;
  margin-left: 28px;
  margin-top: 28px;
  margin-bottom: 30px;
  font-size: 20px;
}
.digital-assets__card-description {
  /* color: var(--font__color-secondary); */
  margin-left: 28px;
  margin-right: 28px;
  margin-bottom: 30px;
  font-size: 15px;
}

.digital-assets__details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* background: rgba(256, 256, 256, 0.4); */
  /* border-top: 1px solid var(--border__color); */
  /* border-bottom: 1px solid var(--border__color); */
}

.digital-assets__left-detail {
  flex: 1;
  padding-top: 28px;
  padding-bottom: 30px;
  padding-left: 28px;
  flex-shrink: 0;
  border-right: 1px solid var(--border__color);
}
.digital-assets__right-detail {
  flex: 1;
  padding-top: 28px;
  padding-bottom: 30px;
  padding-left: 20px;
  flex-shrink: 0
}
.digital-assets__details-label {
  /* color: #8b9bb3; */
  font-size: 11px;
}
.digital-assets__details-value {
  /* color: var(--font__color-primary); */
  font-size: 18px;
  line-height: 15px;
  display: block;
  padding-top: 5px;
}
.digital-assets__additional-details {
  padding-top: 20px;
  padding-left: 20px;
  border-top: 1px solid var(--border__color);
}
.digital-assets__card-actions {
  display: flex;
  align-items: center;
  background: rgba(256, 256, 256, 0.3);
  height: 56px;
  padding: 0 28px;
  border-radius: 0 0 1rem 1rem;
}
.digital-assets__view-more {
  color: #323845; /* psConfig.baseColor */
  padding: 10px;
  margin-left: -10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 1rem;
}
.digital-assets__arrow-icon {
  margin-left: 10px;
  height: 8px
}

.digital-assets__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

.digital-assets__container {
  max-width: 1500px !important;
}

.digital-assets__container-header {
  text-align: center !important;
  font-size: 4rem  !important;
  font-weight: 600 !important;
}

.digital-assets__card {
  background-color: rgba(256, 256, 256, 0.2) !important;

  width: 400px !important;
  border: 1.5px solid rgb(0,0,0,0.12) !important;

  transition: 0.5s ease !important;
}
.digital-assets__card:hover {
  transform: scale(1.02) !important;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2) !important;
}

.digital-assets__card-symbol {
  background-color: #4283FC !important;
  color: #fff !important;
}

.digital-assets__card-description {
  min-height: 264px !important;
}

.digital-assets__left-detail {
  padding-top: 20px !important;
}

.digital-assets__details-label {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 3rem !important;
}


.digital-assets__left-detail{
  border-right: 0 !important;
}

.digital-assets__view-more a {
  color: #380047 !important;
  font-size: 15px !important;
  font-weight: 400;
}
.digital-assets__view-more a:hover {
  color: #019CFE !important;
  font-size: 15px !important;
  font-weight: 400;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .digital-assets__footer {
    margin-top: 3.4rem;
  }
}
