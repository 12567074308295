.App,
#root {
  /* height: 100%; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(260deg, #CC01FF 0%, #00FFDD 100%) 0% 0% no-repeat padding-box;
}

html {
  background: var(--secondary__bg-color);
}
