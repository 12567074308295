.buy-sell__container {
  height: 100%;
  background: none;
  border-radius: 1rem;
}

.buy-sell__tab-wrapper {
  display: inline-flex;
  width: 100%;
}

.buy-sell__buy-sell-hr {
  margin-top: 0;
}


.ap-input__with-label {
  padding-left: 6rem;
}

/* ------   Buy options ----- */

.cp--icon {
  max-width: 50px;
  margin: auto;
  margin-bottom: 2rem;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
}

.buy-options-container {
  display: flex;
  flex-direction: column;
  margin: 3rem;
  width: 40%;
  padding: 3rem;
  border: 10px solid #b81afc;
  border-radius: 80px;
}

.cp--label {
  margin: 0;
  text-align: center;
  color: 1.8rem;
  color: var(--font__color-secondary-light);
  text-align: center;
  margin-bottom: 2rem;
}

.cp--center {
  display: flex;
  align-items: center;
}

.invisible {
  display: none;
}

.cp--text {
  width: 100%;
  text-align: center;
  color: #0defdf;
  font-size: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
}

.cp--text-amt {
  width: 100%;
  text-align: right;
  color: #0defdf;
  font-size: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.cp--text-unit {
  width: 100%;
  text-align: left;
  /* color: black; */
  font-size: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
}

.cp--crypto-amt {
  width: 60%;
  text-align: right;
  color: #0defdf;
  font-size: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.cp--crypto-unit {
  width: 40%;
  text-align: left;
  /* color: black; */
  font-size: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;
}

.cp--text-input {
  width: 100%;
  text-align: center;
  color: #0defdf;
  font-size: 2rem;
  background-color: transparent;
  border-bottom: 1px solid #b81afc;
  margin-left: 5rem;
  margin-right: 5rem;
  caret-color: #0defdf;
}

.buy-options-container .ap--label {
  display: none;
}

.cp--title {
  display: block;
  margin-left: 3.2rem;
  letter-spacing: 0.5px;
  color: var(--font__color-secondary-light);
  -ms-flex-negative: 0;
  flex-shrink: 0;
}


.buy-options-container .label-in-input {
  position: absolute;
  right: 15%;
  margin-top: 1.4rem;
  font-size: 2rem;
  color: var(--font__color-secondary-light);
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 0;
  height: 0;
}

.purchase__btn {
  background-color: #4CAF50;
  border: 2px solid #4CAF50;
  border-radius: 5px;
  color: white;
  height: 4rem;
  padding: 1rem 4rem;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 auto;
  transition-duration: 0.4s;
  cursor: pointer;
}

.purchase__btn:hover {
  background-color: var(--secondary__bg-color);
  color: white;
}


/* ------   Buy options END ----- */

.buy-sell__tab {
  font-size: 1.3rem;
  width: 50%;
  text-align: center;
  height: 5rem;
  line-height: 5rem;
  border-bottom: 1px solid var(--buy-sell__border-color);
  color: var(--font__color-secondary-light);
  font-weight: bold;
  text-transform: uppercase;
}

.buy-sell__vertical-divider {
  width: 1px;
  background: var(--buy-sell__border-color);
}

.buy-sell__tab--buy-selected {
  color: var(--font__color-primary);
  border-bottom: 2px solid var(--exchange-colors__buy);
}

.buy-sell__tab--sell-selected {
  color: var(--font__color-primary);
  border-bottom: 2px solid var(--exchange-colors__sell);
}

.buy-sell__tab:hover {
  color: var(--font__color-primary);
  cursor: pointer;
}

.buy-sell__crypto-container {
  margin: 3.2rem;
}

.buy-sell__extra-info-container {
  margin: 2.4rem 3.2rem 0;
}

.buy-sell__confirm-button-container {
  padding: 3.2rem 3.2rem 3.4rem;
}

.buy-sell__amount-header {
  margin: 4.5rem 3.3rem 1.2rem;
  color: var(--font__color-secondary-light);
  font-size: 1.4rem;
  padding-right: 2.6rem;
  display: flex;
  justify-content: space-between;
}

.buy-sell__form-header {
  padding: 2.8rem 3.3rem;
  color: var(--font__color-primary);
  font-size: 1.8rem;
}

.buy-sell__amount-container {
  border: 1px solid var(--border__color);
  margin: 0 3.2rem;
  display: block;
  background-color: var(--secondary__bg-color);
  border-radius: var(--component__border-radius);
}

.buy-sell__amount-row {
  display: inline-flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border__color);
  padding: 1rem 1.6rem;
  height: 4.4rem;
  color: var(--font__color-secondary-light);
}

.buy-sell__amount-row--active,
.buy-sell__input--active {
  color: var(--font__color-primary);
}

.buy-sell__input--disable {
  color: var(--font__color-secondary-light);
}

.buy-sell__amount-row div {
  font-size: 1.6rem;
  font-weight: normal;
  letter-spacing: 0.1rem;
  padding-bottom: 0.1rem;
}

.buy-sell__amount-row div:nth-of-type(2) {
  min-width: 7.2rem;
  text-align: right;
  width: 50%;
}

.buy-sell__amount-row div:nth-of-type(3) {
  min-width: 9rem;
  text-align: right;
  width: 50%;
}

.buy-sell__amount-row:not(.buy-sell__amount-row--one-input-layout):not(.buy-sell__amount-row--two-inputs-layout) div:nth-of-type(2),
.buy-sell__amount-row:not(.buy-sell__amount-row--two-inputs-layout) div:nth-of-type(3) {
  padding-right: 1rem;
}

.buy-sell__amount-row:last-child {
  border: 0;
}

.buy-sell__input {
  letter-spacing: 0.1rem;
  max-width: 80%;
  margin-left: 20%;
  margin-bottom: -25px;
  text-align: right;
  font-size: 1.5rem;
  border: 1px solid transparent;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  height: 3.2rem;
  border-radius: var(--component__border-radius);
  caret-color:#0defdf;
  color: #0defdf !important;
}

.buy-sell__input-wrapper {
  margin: 0;
  align-content: center;
}

.buy-sell__first-in-row .ap-radio__label {
  padding-right: 0;
  /* width: 10%; */
}

.buy-sell__verification {
  max-width: 90%;
  margin-top: 2rem;
  margin-left: 2rem;
  border-radius: var(--component__border-radius);
}

.buy-sell__btn {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  border-radius: var(--component__border-radius);
  font-weight: bold;
  font-size: 1.5rem;
  color: #fafafa !important;
}

.buy-sell__container .ap-select__select {
  background-color: var(--secondary__bg-color);
  font-size: 1.7rem;
  height: 4.4rem;
  line-height: 4rem;
}

.buy-sell__container .ap-radio__check {
  border: 0 solid transparent;
  background: var(--buy-sell__radio-bg-color);
}

.ap-radio__checked {
  background-color: var(--buy-sell__radio-check-bg-color--selected);
}

.ap-radio input[type='radio']:checked ~ label > .ap-radio__check {
  background-color: var(--buy-sell__radio-bg-color--selected);
  border: 3px solid var(--buy-sell__radio-border-color--action);
}

.ap-radio__label:hover .ap-radio__check {
  border: 2px solid var(--buy-sell__radio-check-border-color--action);
  background-color: var(--buy-sell__radio-check-bg-color--selected);
}

.buy-sell__select-label {
  font-size: 1.4rem;
}

.buy-sell__select {
  -webkit-appearance: none;
  border-radius: 4px;
  color: white !important;

}

.buy-sell__triangles-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1.3rem;
  bottom: 1.25rem;
  pointer-events: none;
}

.buy-sell__arrow {
  padding-left: 2rem;
  font-size: 1.6rem;
  margin-right: -2.5rem;
  padding-left: 1rem;
  padding-top: 0.8rem;
  transition: var(--transition__settings);
}

.buy-sell__icon {
  padding-right: 3rem;
}

.buy-sell__container--alt-layout {
  background: none;
}

.buy-sell__vertical-divider--alt-layout,
.buy-sell__amount-header--alt-layout {
  display: none;
}

.buy-sell__amount-container--alt-layout {
  margin-top: 3.2rem;
  background-color: var(--component__header-bg-color);
}

.buy-sell__input--alt-layout {
  background-color: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 680px) {

  .credit-card-make-transaction {
    padding: 0;
  }
  
  .buy-options-container {
    display: flex;
    flex-direction: column;
    margin: 3rem;
    width: 100%;
    padding: 3rem;
    /* border: 1px solid #454545; */
    /* border-radius: 5px; */
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 480px) {

  .credit-card-make-transaction {
    padding: 0;
  }

  .buy-options-container {
    display: flex;
    flex-direction: column;
    margin: 3rem;
    width: 100%;
    padding: 3rem;
    /* border: 1px solid #454545;
    border-radius: 5px; */
  }

  .buy-sell__crypto-container {
    margin: 3rem 2.4rem 2rem;
  }

  .buy-sell__amount-header {
    margin: 1rem 1.2rem;
  }

  .buy-sell__amount-container {
    margin: 0 2.4rem;
  }

  .buy-sell__amount-header {
    margin: 4.5rem 3.2rem 1.2rem;
  }

  .buy-sell__confirm-button-container {
    padding: 2.5rem 3.2rem 2.4rem;
  }

  .buy-sell__amount-row {
    padding: 1rem;
  }

  .buy-sell__arrow {
    margin-right: unset;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .buy-sell__input {
    margin-left: unset;
    max-width: unset;
  }

  .buy-sell__container--layout-style-2 {
    background: var(--secondary__bg-color);
  }

  .buy-sell__amount-container--layout-style-2 {
    margin: 2.3rem 2.4rem 0;
  }

  .buy-sell__extra-info-container--layout-style-2 {
    margin: 1.6rem 2.4rem 0;
  }
}
